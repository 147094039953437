<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <el-input
      v-model="filterText"
      placeholder="Поиск"
      class="mb-2"
    />
    <b-overlay :show="isReload">
      <div v-if="!isReload">
        <el-tree
          ref="tree"
          :data="categories"
          :props="defaultProps"
          node-key="id"
          show-checkbox
          check-strictly
          :render-after-expand="false"
          :default-checked-keys="selectedKeys"
          :default-expanded-keys="expandedKeys"
          :filter-node-method="filterNode"
          @check-change="handleCheckChange"
          @node-click="handleNodeClick"
        />
      </div>
      <div
        v-else
        style="hight:200px"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
  },
  props: {
    categoryId: {
      type: [Number, Array],
      default: () => [],
    },
  },
  data() {
    return {
      categories: [],
      selectedKeys: [],
      expandedKeys: [1547],
      defaultProps: {
        children: 'children',
        label: 'name_uz',
      },
      title: '',
      filterText: '',
      visible: false,
      isReload: false,
      required,
    }
  },
  computed: {
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  mounted() {
    this.getItems()
    this.title = this.$t('Выберите категорию')

    this.selectedKeys = this.categoryId
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    edit(item) {
      this.form.id = item.id
      this.visible = true
    },
    handleCheckChange(data, checked, node) {
      if (checked) { this.$refs.tree.setCheckedKeys([data.id]) }
    },
    save() {
      const perms = this.$refs.tree.getCheckedNodes()
      this.$emit('onSuccess', perms)
      this.visible = false
    },
    filterNode(value, data) {
      console.log(value, data)
      if (!value) return true
      return data.name_uz.indexOf(value) !== -1
    },

    handleNodeClick(data) {
      if (data.level == 2 && !data.children) {
        this.isReload = true
        this.getCategories({ parent_id: data.id }).then(res => {
          data.children = res.data.data
          this.expandedKeys = [data.id]
        })
          .finally(() => {
            this.isReload = false
          })
      }
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ for_hired_worker: 'not', to_level: 2 })
        .then(res => {
          this.categories = res.data
        })
        .finally(() => { this.loading = false })
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    ...mapActions({
      getItemsAction: 'productCategory/tree',
      getCategories: 'productCategory/index',
    }),
  },
}
</script>

<style>
.el-tree-node__label {
    font-size: 18px !important;
    margin: 3px 0;
}
.el-tree-node__content {
  height: 31px;
}
</style>
