<template>
  <div>
    <CategoriesTable />
  </div>
</template>

<script>
import CategoriesTable from './components/tableCategory.vue'

export default {
  name: 'Index',
  components: {
    CategoriesTable,
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style>
.node-title {
  font-size: 18px;
}
.el-table .warning-row {
  background: #fff7e9;
}

.el-table .success-row {
  background: #e9fff2;
}
</style>
